import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import PropTypes from "prop-types";
import Heading from "@ui/heading";
import Text from "@ui/text";
import { SectionWrap, SliderWrap, SliderWrapInner } from "./style";
import Slider from "react-slick";

const settings = {
  dots: true,
  arrow:true,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,  
  autoplaySpeed: 5000,
  adaptiveHeight: true,
};

const Slider3D = ({ data, layout, ...props }) => {
  return (
    <SectionWrap layout={layout} {...props} id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              title={data.section_title?.title}
              subtitle={data.section_title?.subtitle}
              description={data.section_title?.description}
            />
          </Col>
        </Row>
        <SliderWrap>
          <Slider {...settings}>
            {data?.items &&
              data?.items?.map((el, index) => (
                  <SliderWrapInner key={index}>
                    <Row>
                      <Col lg={6}>
                        <Image src={el?.images[0]?.src} alt={el?.images[0].alt} title={el?.images[0].alt} />
                      </Col>
                      <Col lg={6}>
                        <Heading as="h4">{el?.title}</Heading>
                        <Text>{el?.description}</Text>
                      </Col>
                    </Row>
                  </SliderWrapInner>
              ))}
          </Slider>
        </SliderWrap>
      </Container>
    </SectionWrap>
  );
};

Slider3D.propTypes = {
  items: PropTypes.object,
};

export default Slider3D;
