import styled, { css, device } from "@styled";
import previousArrow  from "@data/images/icons/previous-arrow.png";
import nextArrow  from "@data/images/icons/next-arrow.png";
export const SectionWrap = styled.section`
${(props) =>
    props.layout === 1 &&
    css`
    background: linear-gradient(320deg, rgb(21, 5, 79), rgb(86, 23, 131));
    padding:40px 0;
    ${device.small} {
      padding:60px 0;
    }
    ${device.large} {
      padding:80px 0;
    }
    ${device.xxlarge} {
      padding:100px 0;
    }
    .section-title{
    margin:0;
      h6, h2, p{
        color:#fff;
      }
    }
  `}
`;
export const SliderWrap = styled.div`
  margin-top: 30px;
  ${device.medium} {
    margin-top: 50px;
  }
  ${device.xxlarge} {
    margin-top: 70px;
  }
  .slick {
    &-list{
      transition:all .7s linear;
    }
    &-dots {
      position:unset;
      text-align:right;
      margin-top:30px;
      ${device.medium} {
        margin-top:50px;
      }
      ${device.xxlarge} {
        margin-top:70px;
      }
      li {
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        border-radius:50%;
        margin:0;
        ${device.xlarge} {
          width: 14px;
          height: 14px;
        }
        &:not(:last-child){
          margin-right:8px;
          ${device.xlarge} {
            margin-right:10px;
          }
        }
        button {
          padding: 0;
          width: 10px;
          height: 10px;
          ${device.xlarge} {
            width: 14px;
            height: 14px;
          }
          &::before {
            content: none;
          }
        }
        &.slick-active {
          border-color: #fff;
          background: #fff;
        }
      }
    }
    &-arrow{
      top:calc(100% - 17px);
      transform: translate(0, 0);
      left:0;
      right:auto;
      width:20px;
      height:20px;
      ${device.xlarge} {
        width: 30px;
        height: 30px;
        top:calc(100% - 22px);
      }
      &::before{
        display:none;
      }
      &.slick-prev{
        background:url(${previousArrow});
        background-size:contain;
        background-repeat:no-repeat;
      }
      &.slick-next{
        background:url(${nextArrow});
        background-size:contain;
        background-repeat:no-repeat;
        left:30px;
        ${device.xlarge} {
          left:45px;
        }
      }
    }
  }
`;
export const SliderWrapInner = styled.div`
  position: relative;
  .row{
    grid-gap:30px 0;
    align-items:center;
    .col-lg-6{
      h4{
        font-size: 20px;
        letter-spacing: 1px;
        line-height: 1;
        color: #ffffff;
        font-weight: 700;
        ${device.xlarge} {
          font-size: 22px;
        }
      }
      p{
        font-size: 15px;
        letter-spacing: 0px;
        line-height: 1.5294;
        color: #ffffff;
        margin-top:20px;
        ${device.xlarge} {
          font-size: 17px;
          margin-top:15px;
        }
      }
    }
  }
`;
