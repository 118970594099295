import * as React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-01";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import IntroAreaOne from "@components/introarea/layout-4";
import LLMsSlider from "@containers/artificial-intelligence/llms-slider";
import HumanInTheLoop from "@containers/artificial-intelligence/human-in-the-loop";
import IntroArea from "@components/introarea/layout-6";
import CaseStudy from "@containers/global/case-study";
import ContactArea from "@containers/contact-us";
import { caseStudiesData } from "@constants";
import QuoteForm from "@containers/translation-quote-form";
const LargeLanguageModels = ({ location, data }) => {
  const content = normalizedData(data?.page.content || []);
  const globalContent = normalizedData(data?.allGeneral.nodes || []);

  return (
    <Layout location={location}>
      <Seo
        title="Large Language Models (LLMs)"
        description="AI-powered LLMs in translation have revolutionized the way content is translated making it easier than ever to produce high-quality translations quickly and cost-effectively."
        />
      <Header
        data={{
          ...globalContent["menu"],
          ...data.site.siteMetadata,
        }}
      />
      <main className="site-wrapper-reveal">
        <PageHeader data={content["llm-page-header"]} />
        <QuoteForm />
        <IntroAreaOne layout={1} data={content["llm-intro-body"]} />
        <LLMsSlider layout={1} data={content["llms-slider-body"]} />
        <HumanInTheLoop layout={1} data={content["human-in-the-loop-data"] }/>
        <IntroArea layout={3} data={content["quality-control-data"]} />
        <CaseStudy layout={3} data={caseStudiesData} />
        <ContactArea Layout={2} />
      </main>
      <Footer data={{ ...data.site.siteMetadata }} />
    </Layout>
  );
};

export const query = graphql`
  query LargeLanguageModelsQuery {
    allGeneral {
      nodes {
        section
        ...HeaderOne
      }
    }
    site {
      ...Site
    }
    page(title: { eq: "large-language-models" }, pageType: { eq: "innerpage" }) {
      content {
        ...PageContent
      }
    }
  }
`;

LargeLanguageModels.propTypes = {
  location: PropTypes.shape({}),
  data: PropTypes.shape({
    allGeneral: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        contact: PropTypes.shape({}),
      }),
    }),
    page: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
};

export default LargeLanguageModels;
